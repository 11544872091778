import { Box, FormControl, FormControlProps } from '@chakra-ui/react';
import { useField } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';

export interface IReCaptchaFieldProps extends FormControlProps {
  /**
   * Recaptcha name.
   */
  name: string;
}

export const ReCaptchaField: React.FC<IReCaptchaFieldProps> = (
  props,
): JSX.Element => {
  const { name, ...rest } = props;

  const [, meta, helpers] = useField(name);

  const isInvalid = !!meta.error && !!meta.touched;

  return (
    <FormControl isInvalid={isInvalid} name={name} {...rest}>
      <Box display={{ base: 'none', md: 'block' }}>
        <ReCAPTCHA
          onChange={(token) => helpers.setValue(token)}
          sitekey="6Lc2EE8qAAAAABsr8ynJFl1yEEa9OuwKqgRl1k0j"
          size="normal"
        />
      </Box>
      <Box display={{ base: 'block', md: 'none' }}>
        <ReCAPTCHA
          onChange={(token) => helpers.setValue(token)}
          sitekey="6Lc2EE8qAAAAABsr8ynJFl1yEEa9OuwKqgRl1k0j"
          size="compact"
        />
      </Box>
    </FormControl>
  );
};
