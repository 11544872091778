import { Flex, Image, Text } from '@chakra-ui/react';
import { useMachine } from '@xstate/react';
import { H1 } from 'components/elements';
import { LogInForm } from 'components/forms';
import { authActions } from 'containers/AuthManager/store';
import { addErrorToast } from 'containers/ToastManager/store/slice';
import { FormikHelpers } from 'formik';
import Cover from 'images/login-image.png';
import { use100vh } from 'react-div-100vh';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AuthResources } from 'services/resources/auth';
import {
  ILoginEmailPayload,
  ILoginPayload,
} from 'services/resources/auth/types.d';
import { getLogInMachine } from 'x-state/machines/LogInMachine';

import { Paper } from './components/Paper';

export const LoginPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();

  const height = use100vh();

  const [state, send] = useMachine(getLogInMachine());

  const verifyPermissions = async (
    { email }: ILoginEmailPayload,
    helpers?: FormikHelpers<ILoginEmailPayload>,
  ) => {
    try {
      await AuthResources.prepare({ email });
      send('NEXT', { email });
    } catch (e) {
      dispatch(addErrorToast('toast.errors.login.permissions'));
    } finally {
      helpers?.setSubmitting(false);
    }
  };

  const handleOnSubmit = (
    payload: ILoginPayload,
    helpers?: FormikHelpers<ILoginPayload>,
  ) => {
    dispatch(authActions.auth(payload));
    helpers?.setSubmitting(false);
  };

  return (
    <Flex
      align="center"
      bg="blue.50"
      h={`${height}px`}
      justify="center"
      overflow="hidden"
      position="relative"
      px={{ base: 4, md: 16 }}
    >
      <Paper>
        <Flex
          direction="column"
          flex={{ base: '0 0 100%', lg: '0 0 40%' }}
          flexGrow={1}
          justify="center"
          px={{ base: 4, md: 12 }}
          py={12}
        >
          <H1 color="blackAlpha" letterSpacing="wider" textAlign="center">
            <Trans i18nKey="title" ns="login">
              <Text as="span" color="blue" fontWeight="bold" />
              <Text as="span" />
            </Trans>
          </H1>
          <LogInForm
            initialValues={{
              email: state?.context?.email,
              password: '',
              recaptcha: '',
            }}
            onEmailSubmit={verifyPermissions}
            onSubmit={handleOnSubmit}
            node={state?.value as string}
          />
        </Flex>

        <Flex display={{ base: 'none', lg: 'flex' }} flex="0 0 60%">
          <Image
            alt="login-image"
            fit="cover"
            flex={2}
            h="100%"
            src={Cover}
            w="100%"
          />
        </Flex>
      </Paper>
    </Flex>
  );
};
